@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;900&family=Source+Code+Pro:wght@200;400;900&display=swap");

:root {
  --page-offset: 20px;
  --container-width: 800px;
  --golden-ratio: 1.618;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
  background-color: #1d1e20;
  font-family: "Roboto";
  font-weight: 400;
}

a {
  color: inherit;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

.text-center {
  text-align: center;
}

#root {
  min-height: 100%;
}

.page {
  padding: var(--page-offset);
  /* box-sizing: border-box; */
  height: calc(100vh - var(--page-offset) - var(--page-offset));
}

.content {
  margin-top: 10px;
}

.js {
  position: absolute;
  left: var(--page-offset);
  top: var(--page-offset);
  width: 50px;
}

.footer {
  width: 100%;
  position: absolute;
  bottom: var(--page-offset);
  left: 0;
  color: #888;
  font-size: 10px;
  font-family: Roboto;
  font-weight: 300;
  text-align: center;
}

.footer a {
  color: inherit;
  text-decoration: none;
}

.footer a:hover {
  color: #ccc;
  /* text-decoration: underline; */
}

.container {
  width: 100%;
  max-width: var(--container-width);
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.container--info {
  max-width: calc(var(--container-width) / var(--golden-ratio));
}

.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100%;
}

.rocket {
  position: absolute;
  top: 0px;
  left: 105%;
}

.title-container {
  text-align: center;
}

.title {
  display: inline-block;
  position: relative;
  margin-top: -0.2em;
  margin-bottom: 10px;
  font-weight: 900;
  font-size: 40px;
}

.subtitle {
  margin-top: 0;
  margin-bottom: 40px;
  font-weight: 200;
  font-size: 20px;
  text-align: center;
}

h1,
h2 {
  color: #ccc;
  font-family: Roboto;
}

.benchmarks {
  margin-top: 30px;
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.benchmark {
  flex: 1;
}

.benchmarks div.run-container {
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.run {
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.run.run--busy {
  cursor: not-allowed;
}

.play-icon-container {
  position: absolute;
  top: 0;
  left: 0;
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
}

.gear-icon {
  width: 100%;
  height: 100%;
  /* background: url("./gear.svg") no-repeat center center; */
  transition: transform linear 0.2s;
}

.gear-icon--rotate {
  animation: rotation 1.5s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

textarea {
  display: block;
  width: 100%;
  min-height: 50px;
  background-color: #2f3134;
  border: none;
  border-radius: 5px;
  resize: none;
  color: #ddd;
  padding: 10px 15px;
  box-sizing: border-box;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-family: "Source Code Pro", monospace;
}

button {
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
}

.rails {
  margin-top: 35px;
}

.rails--hidden {
  visibility: hidden;
}

.rail {
  /* border: 1px solid #bbb; */
  background-color: #333;
  height: 2px;
  margin-top: 20px;
}

.bar {
  width: 0%;
  height: inherit;
  background-color: #efdb50;
  transition-property: width;
  transition-timing-function: linear;
  /* transition-duration: 0.2s; */
}

.play-icon {
  left: 30%;
  top: 28%;
  /* display: block; */
  /* box-sizing: border-box; */
  transform: scale(1.5);
  width: 50%;
  height: 50%;
  position: absolute;
  /* border: 1px solid red; */
}

.play-icon::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 7px solid #1d1e20;
  top: 6px;
  left: 9px;
}

.info {
  background-color: #111;
  color: #ccc;
  padding: var(--page-offset);
  font-size: 14px;
  line-height: 28px;
}

hr {
  background-color: #333;
  height: 1px;
  border: none;
  margin-block: var(--page-offset);
}

.signature {
  text-decoration: none;
  letter-spacing: 5px;
  font-size: 8px;
  padding: 10px 12px 10px 15px;
  border-radius: 20px;
}

.signature:hover {
  background-color: #222;
}

@media screen and (min-width: 600px) {
  .content {
    margin-top: 0;
  }
  .title {
    font-size: 70px;
  }
  .subtitle {
    font-size: 24px;
  }
  .benchmarks {
    flex-direction: row;
  }
  textarea {
    min-height: 150px;
  }
}

@media (hover: hover) {
  .run:hover .gear-icon {
    transform: rotate(45deg);
  }
}
